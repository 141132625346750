<template>
  <div
    style="
    padding: 15px;"
  >
    <v-card class="px-3 py-2">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
            DAFTAR KENDARAAN
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h6>
        </v-col>
        <v-col cols="12">
          <div>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <v-btn
                @click="addVehicle"
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px;"
                :disabled="loading"
              >
                + Tambah Data
              </v-btn>
              <v-btn
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px; margin-left:10px;"
                :disabled="loading"
                @click="syncFromOdoo"
              >
                Sync odoo
              </v-btn>
              <div
                style="width: 180px; display: flex; flex-direction: row; margin-left: auto;margin-right:15px; "
              >
                <v-select
                  v-model="paramApi.company_id"
                  :items="dropdown.company"
                  @change="companyWatcher"
                  style="height:40px;"
                  label="Kepemilikan"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div
                style="width: 180px; display: flex; flex-direction: row;margin-right:15px; "
              >
                <v-select
                  v-model="paramApi.vehicle_type_id"
                  :items="dropdown.vehicle_type"
                  @change="fuelTypeWatcher"
                  style="height:40px;"
                  label="Jenis Kendaraan"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div style="width: 180px; display: flex; flex-direction: row;">
                <v-select
                  v-model="paramApi.fuel_type_id"
                  :items="dropdown.fuel_type"
                  @change="fuelTypeWatcher"
                  style="height:40px;"
                  label="Bahan Bakar"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
            </v-toolbar>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <div style="width: 120px; display: flex; flex-direction: row;">
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                  v-model="actionValue"
                  :items="[
                    { id: 0, name: '' },
                    { id: 1, name: 'Delete' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="viewAction"
                ></v-select>
              </div>

              <div
                style="width: 375px; display: flex; flex-direction: row; margin-left: auto;"
              >
                <v-text-field
                  v-model="paramApi.keyword"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  label="Cari Nopol"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  style="position: relative; height:40px;"
                ></v-text-field>
              </div>
              <!-- <div style="width: 180px; display: flex; flex-direction: row;">
                <v-autocomplete
                  v-model="paramApi.vehicle_type_id"
                  :items="dropdown.vehicleType"
                  :search-input.sync="dropdown.vehicleTypeSearch"
                  @change="vehicleTypeWatcher"
                  label="Loading"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div> -->
            </v-toolbar>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            item-key="id"
            mobile-breakpoint="0"
            fixed-header
            height="60vh"
            :headers="headers"
            style="cursor: pointer;"
            :items="result"
            :loading="loading"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            show-select
            :single-select="true"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="addDialog" width="800" persistent>
      <v-form
        v-if="form !== null"
        :disabled="loading || (form.act === 'update_from_odoo' && !isEdit)"
        lazy-validation
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative;"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Formulir Tambah / Edit
          </v-card-title>

          <v-card-text
            class="d-flex justify-center flex-column"
            style="margin-top:10px;"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:10px;"
              >
                <div class="d-flex" style="padding: 0 10px;width:100%;">
                  <v-col
                    cols="12"
                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                  >
                    <p
                      style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Milik Internal ?
                    </p>

                    <v-checkbox
                      v-model="form.is_internal"
                      :true-value="1"
                      :false-value="0"
                      style=" margin:0 0 0 10px; height:25px; padding:0;"
                    ></v-checkbox>
                    <div
                      style="margin-left:auto;"
                      v-if="form.act === 'update_from_odoo'"
                    >
                      <a
                        v-if="!isEdit"
                        href=""
                        @click.prevent="isEdit = true"
                        style="font-size:14px;"
                        class="d-flex flex-row"
                      >
                        <v-icon small color="#1976d2" style="margin-right:3px;">
                          mdi-application-edit-outline
                        </v-icon>
                        Edit data
                      </a>
                      <p
                        v-else
                        @click.prevent="isEdit = false"
                        style="font-size:14px; margin:0; color:red; cursor: pointer;"
                        class="d-flex flex-row"
                      >
                        Batal
                        <v-icon small color="red" style="margin-left:3px;">
                          mdi-close
                        </v-icon>
                      </p>
                    </div>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nomor Polisi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.license_plate"
                      :rules="rules.nopolRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kepemilikan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.company"
                      :items="dropdown.company"
                      :rules="rules.companyRules"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kode GPS Vendor
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.gps_vendor_code"
                      :rules="rules.gpsVendorRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Jenis Kendaraan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.vehicle_type_id"
                      :items="dropdown.vehicle_type"
                      :rules="rules.vehicleTypeRules"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      ID GPS Vendor
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.gps_vendor_id"
                      :rules="rules.gpsIdRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Bahan Bakar
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;"
                    ><v-select
                      v-model="form.fuel_type_id"
                      :items="dropdown.fuel_type"
                      :rules="rules.fuelTypeRules"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Odoo ID
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field v-model="form.odoo_id" dense outlined />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Status
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.status_id"
                      :items="[
                        {
                          id: 1,
                          name: 'Active'
                        },
                        {
                          id: 0,
                          name: 'Inactive'
                        }
                      ]"
                      :rules="rules.status"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <v-divider />
            <v-row no-gutters style="position:relative; top:20px;">
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi Pull
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      v-model="form.pull_location_id"
                      :items="dropdown.pullLocation"
                      :search-input.sync="dropdown.pullLocationSearch"
                      label="Ketik"
                      style="margin: 0;height: 40px;"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      clearable
                      hide-no-data
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi Saat Ini
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      v-model="form.current_location_id"
                      :items="dropdown.currentLocation"
                      :search-input.sync="dropdown.currentLocationSearch"
                      label="Ketik"
                      style="margin: 0;height: 40px;"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      clearable
                      hide-no-data
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi Sebelumnya
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;"
                    ><v-autocomplete
                      v-model="form.previous_location_id"
                      :items="dropdown.previousLocation"
                      :search-input.sync="dropdown.previousLocationSearch"
                      label="Ketik"
                      style="margin: 0;height: 40px;"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      clearable
                      hide-no-data
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              @click="closeDialog"
              color="error"
              text
              outlined
              type="reset"
              :loading="loading"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              text
              outlined
              type="submit"
              :loading="loading"
              :disabled="form.act === 'update_from_odoo' && !isEdit"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'msvehicle',
  components: {},
  data: () => ({
    expedition: buildType.apiURL('expedition'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    loading: false,
    totalData: 0,
    result: [],
    paramApi: {
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      page: 1,
      orderBy: 'name',
      orderType: 'asc',
      loading_from_location_id: null,
      vehicle_type_id: null,
      company_id: null,
      fuel_type_id: null,
      isInternal: 0
    },
    addDialog: false,
    form: null,
    isEdit: false,
    options: {},

    headers: [
      {
        text: 'Nomor Polisi',
        value: 'license_plate',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tipe Kendaraan',
        value: 'vehicle_type.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Bahan Bakar',
        value: 'fuel_type.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'GPS ID',
        value: 'gps_vendor_id',
        align: 'left',
        sortable: false
      },
      {
        text: 'GPS Code',
        value: 'gps_vendor_code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Odoo ID',
        value: 'odoo_id',
        align: 'left',
        sortable: false
      },
      {
        text: 'Kepemilikan',
        value: 'company.name',
        align: 'left',
        sortable: false
      }
    ],
    enableWatcher: true,
    selectedData: [],
    actionValue: 0,
    dropdown: {
      company: [],
      vehicle_type: [],
      fuel_type: [],

      pullLocation: [],
      pullLocationSearch: null,
      pullLocationSelect: null,

      currentLocation: [],
      currentLocationSearch: null,
      currentLocationSelect: null,

      previousLocation: [],
      previousLocationSearch: null,
      previousLocationSelect: null
    },
    rules: {
      companyRules: [],
      nopolRules: [],
      vehicleTypeRules: [],
      fuelTypeRules: [],
      gpsVendorRules: [],
      gpsIdRules: [],
      statusRules: []
    }
  }),
  async mounted() {
    this.getDataFromApi()
    this.initDropdown()
  },
  created() {},
  watch: {
    'dropdown.pullLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.pullLocationSelect &&
          this.dropdownPullLocation(val)
      } else {
        this.dropdown.pullLocationSelect = null
        this.dropdown.pullLocation = []
      }
    },
    'dropdown.currentLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.currentLocationSelect &&
          this.dropdownCurrentLocation(val)
      } else {
        this.dropdown.currentLocationSelect = null
        this.dropdown.currentLocation = []
      }
    },
    'dropdown.previousLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.previousLocationSelect &&
          this.dropdownPreviousLocation(val)
      } else {
        this.dropdown.previousLocationSelect = null
        this.dropdown.previousLocation = []
      }
    }
  },
  methods: {
    viewAction(event) {
      switch (event) {
        case 1:
          this.deleteItem()
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    refreshPage() {
      this.getDataFromApi()
      this.initDropdown()
    },
    async getDataFromApi() {
      this.result = []
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      var url = `${this.expedition}vehicle/list?keyword=${
        this.paramApi.keyword
      }&offset=${this.paramApi.offset * this.paramApi.limit}&limit=${
        this.paramApi.limit
      }&isInternal=&status=`

      const company_id = `&company=${
        this.paramApi.company_id !== null ? this.paramApi.company_id : ''
      }`
      const vehicle_type_id = `&type=${
        this.paramApi.vehicle_type_id !== null
          ? this.paramApi.vehicle_type_id
          : ''
      }`
      const fuel_type_id = `&fuel=${
        this.paramApi.fuel_type_id !== null ? this.paramApi.fuel_type_id : ''
      }`
      url = url + company_id + vehicle_type_id + fuel_type_id

      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
      this.dropdownVehicleType()
      this.dropdownFuelType()
      // this.dropdownLoadingLocation('')
    },

    async companyWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async vehicleTypeWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async fuelTypeWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async dropdownCompany() {
      this.dropdown.company = []
      await axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            arr.unshift({ id: '', name: 'All' })
            return (this.dropdown.company = arr)
          }
          return (this.dropdown.company = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.company = [])
        })
    },
    async dropdownVehicleType() {
      this.dropdown.vehicle_type = []
      await axios
        .get(`${this.expedition}vehicle_type/dropdown?keyword=`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            arr.unshift({ id: '', name: 'All' })
            return (this.dropdown.vehicle_type = arr)
          }
          return (this.dropdown.vehicle_type = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.vehicle_type = [])
        })
    },
    async dropdownFuelType() {
      this.dropdown.fuel_type = []
      await axios
        .get(`${this.expedition}fuel_type/dropdown?keyword=`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            arr.unshift({ id: '', name: 'All' })
            return (this.dropdown.fuel_type = arr)
          }
          return (this.dropdown.fuel_type = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.fuel_type = [])
        })
    },
    async dropdownPullLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.pullLocation = res.data.data)
          }
          return (this.dropdown.pullLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.pullLocation = [])
        })
    },
    async dropdownCurrentLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.currentLocation = res.data.data)
          }
          return (this.dropdown.currentLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.currentLocation = [])
        })
    },
    async dropdownPreviousLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.previousLocation = res.data.data)
          }
          return (this.dropdown.previousLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.previousLocation = [])
        })
    },
    async syncFromOdoo() {
      this.showMsgDialog('question', 'Sync data dari Odoo sekarang', true).then(
        async res => {
          if (res.isConfirmed) {
            this.loading = true
            await axios
              .get(`${this.expedition}vehicle/sync_from_odoo`)
              .then(res => {
                console.log(res.data)
                this.loading = false
                if (res.data.status_code === '00') {
                  this.showMsgDialog('success', res.data.status_msg, false)
                  this.getDataFromApi()
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              })
              .catch(err => {
                console.log(err)
                this.showMsgDialog('error', err, false)
              })
          } else {
            this.actionValue = 0
          }
        }
      )
    },
    rowClick(pItem) {
      this.setForm(pItem)

      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    addVehicle() {
      this.setForm()
      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    submit() {
      this.rules = {
        companyRules: [v => !!v || 'Company is required'],
        nopolRules: [v => !!v || 'Nopol is required'],
        vehicleTypeRules: [v => !!v || 'Vehicle type is required'],
        fuelTypeRules: [v => !!v || 'Fuel type is required'],
        gpsVendorRules: [v => !!v || 'GPS Vendor code is required'],
        gpsIdRules: [v => !!v || 'GPS Vendor ID is required'],
        statusRules: [v => !!v || 'Status is required'],
        ownedRules: [v => !!v || 'isInternal is required']
      }
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        fuel_type_id: this.form.fuel_type_id,
        license_plate: this.form.license_plate,
        vehicle_type_id: this.form.vehicle_type_id,
        odoo_id: this.form.odoo_id,
        current_location_id: this.form.current_location_id,
        pull_location_id: this.form.pull_location_id,
        previous_location_id: this.form.previous_location_id,
        gps_vendor_id: this.form.gps_vendor_id,
        gps_vendor_code: this.form.gps_vendor_code,
        status_id: this.form.status_id,
        is_internal: this.form.is_internal
      }
      console.log(form)
      if (this.form.act === 'update_from_odoo') {
        Object.assign(form, { id: this.form.id })
      }
      // if (this.form.act === 'add') {
      //   Object.assign(form, {
      //     id: Math.floor(Math.random() * 1000000)
      //   })
      // }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.expedition}vehicle/save`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            const self = this
            setTimeout(() => {
              self.closeDialog()
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    closeDialog() {
      this.addDialog = false
      this.$refs.entryForm.reset()
      this.form = null
    },
    async deleteItem() {
      this.showMsgDialog(
        'question',
        'Anda yakin ingin menghapus data ini sekarang',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.expedition}vehicle/delete/${this.selectedData[0].id}`
            )
            .then(res => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.setAction()
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch(err => {
              console.log(err)
              this.showMsgDialog('error', err, false)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    searchEnter() {
      this.getDataFromApi()
    },
    async updatePage(p) {
      this.paramApi.page = p
      this.paramApi.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramApi.limit = p
      this.paramApi.offset = 0
      await this.getDataFromApi()
    },
    setForm(xData) {
      console.log(xData)
      const x = xData !== undefined
      this.form = {
        act: x ? 'update_from_odoo' : 'add_from_odoo',
        company: x ? xData.company : null,
        fuel_type_id: x
          ? xData.fuel_type !== null
            ? xData.fuel_type.id
            : null
          : null,
        license_plate: x ? xData.license_plate : '',
        vehicle_type_id: x
          ? xData.vehicle_type !== null
            ? xData.vehicle_type.id
            : null
          : null,
        odoo_id: x ? xData.odoo_id : null,
        current_location_id: x
          ? xData.current_location !== null
            ? xData.current_location.id
            : null
          : null,
        pull_location_id: x
          ? xData.pull_location !== null
            ? xData.pull_location.id
            : null
          : null,
        previous_location_id: x
          ? xData.previous_location !== null
            ? xData.previous_location.id
            : null
          : null,
        gps_vendor_id: x ? xData.gps_vendor_id : null,
        gps_vendor_code: x ? xData.gps_vendor_code : null,
        is_internal: x ? xData.is_internal : 1,
        status_id: x
          ? xData.status !== null
            ? Number(xData.status.id)
            : null
          : null
      }
      if (x) {
        Object.assign(this.form, { id: xData.id })
        if (xData.previous_location !== null) {
          this.dropdownPreviousLocation(xData.previous_location.name)
        }
        if (xData.pull_location !== null) {
          this.dropdownPullLocation(xData.pull_location.name)
        }
        if (xData.current_location !== null) {
          this.dropdownCurrentLocation(xData.current_location.name)
        }
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
